<template>
  <!-- 
    画面: 画像カテゴリ一覧
    用途: 画像カテゴリ一覧を参照する
   -->
  <div>
    <!-- #region TODO -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <strong class="mr-3"> {{ $t("imageCategoryListPage.pageTitle") }}</strong>
            <CButton color="info"  @click="onCreateClicked()">{{
              $t("imageCategoryListPage.createButton")
            }}</CButton>
          </CCardHeader>
          <CCardBody>
            <ImageCategoryListTable />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
// import axios from "axios";
import ImageCategoryListTable from "./components/ListTable";

export default {
  name: "ImageCategoryList",

  components: {
    ImageCategoryListTable,
  },

  props: {},

  data() {
    return {
      //#region Flag

      loading: false,

      //#endregion Flag
    };
  },

  methods: {
    //#region Method

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `/operator/image-category/create`,
      });
    },

    //#endregion Method
  },
};
</script>