<template>
  <!-- 
    画面: 画像カテゴリ一覧テーブル
    用途: 登録された画像カテゴリのデータを一覧表示する
   -->
  <div>
    <!-- #region Table -->
    <CDataTable
      border
      hover
      size="sm"
      :fields="tableFields"
      :items="tableItems"
      v-if="canShowTable"
    >
      <td slot="name" slot-scope="{ item }">
        <span class="ml-2 text-left">{{ item.name }}</span>
      </td>
      <td slot="status" slot-scope="{ item }" class="text-center p-0">
        <CSwitch
          class="mt-1 swc sw-2"
          color="info"
          data-on="On"
          data-off="Off"
          variant="opposite"
          :labelOn='$t("common.valid")'
          :labelOff='$t("common.invalid")'
          :checked="item.status"
          @change="onStatusChanged()"
        />
      </td>
      <template #action="{ item }">
        <td class="p-0 text-center">
          <CButton
            class="px-3 mt-1"
            color="info"
            square
            size="sm"
            @click="onEditClicked(item.id)"
            ><i class="icon cil-pencil mr-1"></i>{{ $t("imageCategoryListPage.editButton") }}</CButton
          >
        </td>
      </template>
    </CDataTable>
    <!-- #endregion Table -->

    <!-- #region Alert -->
    <CAlert color="danger" class="mt-3 text-center" v-if="notFound">
      ご利用可能な割引はありません。
    </CAlert>
    <!-- #endregion Alert -->
  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";
export default {
  name: "ImageCategoryListTable",

  data() {
    return {
      //#region Flag
      //#endregion Flag

      //#region Table

      /** 一覧表示データ */
      // tableItems: [],
      // TODO: 開発用
      tableItems: [
        {
          description: "",
          id: "1",
          name: "お部屋" 
        },
        {
          description: "",
          id: "2",
          name: "特別フロア" 
        },
      ],

      /** 一覧列名 */
      tableFields: [
        { 
          key: "name",
          label: `${i18n.t("imageCategoryListPage.name")}`,
          _classes: "text-center bg-light"
        },
        {
          key: "status",
          label: `${i18n.t("imageCategoryListPage.status")}`,
          _classes: "text-center bg-light",
           _style: "width: 10%"
        },
        {
          key: "action",
          label: `${i18n.t("imageCategoryListPage.action")}`,
          _classes: "text-center bg-light",
           _style: "width: 10%"
        },
      ],

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },
  },

  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged() {
      // TODO: 有効無効ステータス更新リクエスト
      this.postUpdateStatus();
    },

    /** 編集ボタン押下 */
    onEditClicked(id) {
      const imageCategoryId = id;
      this.$router.push({
        path: `/operator/imageCategory/${imageCategoryId}/edit`,
      });
    },

    //#endregion Event

    //#region Request

    /** 有効無効ステータス更新 */
    postUpdateStatus() {
      // TODO:
    },

    /** 割引プラン一覧取得 */
    getImageCategories() {
      // const url = "/rest/roomType";
      const url = "/rest/imagecategory";

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          // const datas = resp.data;
          const datas = resp.data.categories;
          console.log(datas);

          this.response = datas;

          // 取得データを一覧表に流し込み
          this.pourTable(datas);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },
    //#endregion Request

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          code: data.code,
          name: data.name,
          capacity: `${data.capacityMin} ~ ${data.capacityMax}`,
          baseInventory: data.baseInventory,
          size: data.size,
          status: data.status,
        });
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /**  */
    createTableItems(tableItem) {
      const tableItems = new Array(tableItem);
      return tableItems;
    },

    //#endregion Method
  },

  beforeMount() {
    this.getImageCategories();
  },
};
</script>
